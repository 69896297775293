@mixin form-validation($color: inherit) {
    display: none;
    color: $color;
    font-size: $font-size-smaller;
    line-height: 1.2;
    margin-top: 0.5rem;
}

.form-validation {
    @include form-validation();

    &.is-visible {
        display: block;
    }
}

.form-validation-danger {
    @include form-validation($color: get-color(danger));

    &.is-visible {
        display: block;
    }
}

.form-validation-warning {
    @include form-validation($color: get-color(warning));

    &.is-visible {
        display: block;
    }
}

.logaroo-input-item{
    width: 100%;
    margin-bottom: 20px;
}

.logaroo-input-item__error{
    font-size: 8px;
    color: #ff0000;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 2px;
    margin-top: 5px;
    display: none;
}

input[type="submit"]{
    background-color: get-color(secondary);
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
    text-align: center;
    width: 150px;
    transition: 400ms;
    cursor: not-allowed;
    &:hover{
        opacity: 0.7;
    }
    
    &.disabled{
        background-color: #ccc;
    }
}

.submit-container{
    margin-top: 50px;
    @include media (get-bp(desktop)){
        display: flex;
        justify-content: flex-end;
        position: relative;
        align-items: center;
    }
}

.submit-container__success{
    border: 1px solid #00dd00;
    border-left: 6px solid #00bb00;
    padding: 10px;
    display: flex;
    font-size: 12px;
    margin-right: 30px;
    opacity: 0;
    transition: 400ms;
    display: none;
    margin-bottom: 20px;
    @include media (get-bp(desktop)){
        margin-bottom: 0;
        position: absolute;
        top: 0;
        left: 0;
    }
    .success-icon{
        margin-right: 10px;
        color: #00dd00;
    }
    &.shown{
        display: flex;
    }
    &.opacity{
        opacity: 1;
    }
}