// Importe webfonts aqui.
//
// @example
//     @include import-font-face('Nome da fonte', '/caminho/da/fonte', $weight?, $style?);
//     @include import-font-face('Roboto', '../fonts/roboto/roboto');
//     @include import-font-face('Roboto', '../fonts/roboto/roboto-italic', normal, italic);
//     @include import-font-face('Roboto', '../fonts/roboto/roboto-bold', bold);
//     @include import-font-face('Roboto', '../fonts/roboto/roboto-bold-italic', bold, italic);


@include import-font-face('WorkSans', '../fonts/WorkSans-Medium.woff');