.main-banner{
    user-select: none;
    position: relative;
    @media (min-width: 1200px){
        height: 90vh;
    }
}

.main-banner__slider{
    @media (min-width: 1200px){
        height: 100%;
    }
    .swiper-slide{
        width: 100%;
        height: 100%;
        background-color: #000;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .home-logo-slide{
            background-color: #000;
            width: 100%;
            height: 300px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            .stack{
                width: 250px;
                @media (min-width: 1200px){
                    width: 600px;
                    height: 100%;
                }
            }
            img{
                filter: brightness(0) invert(1);
            }
        }
    }

    .swiper-slide__desktop-image{
        width: 100%;
        height: 100%;
        img{
            width: 100%;
            height: 100%;
        }
        display: none;
        @media (min-width: 1200px){
            display: block;
        }
    }

    .swiper-slide__mobile-image{
        display: block;
        width: 100%;
        height: 100%;
        img{
            width: 100%;
            height: 100%;
        }
        @media (min-width: 1200px){
            display: none;
        }
    }
}

.main-banner__controls{
    padding: 20px;
    position: absolute;
    width: 100%;
    bottom: 10px;
    left: 0;
    z-index: 3;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    @media (min-width: 1200px){
        bottom: 50px;
    }
}

.main-banner__social{
    font-size: 22px;
    .social-item{
        display: flex;
        width: 40px;
        height: 40px;
        background-color: #fff;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-top: 20px;
        transition: 400ms;
        &:hover{
            transform: rotate(10deg);
            background-color: #000;
            color: #fff;
        }
    }
}

.main-banner__navigation{
    font-size: 28px;
    color: #fff;
    display: flex;
    .spacing{
        margin-right: -10px;
        margin-left: 10px;
    }
    i{
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
        transition: 400ms;
        &:hover{
            transform: scale(1.2);
        }
    }
}


