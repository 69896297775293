.mobile-menu-is-open {
    @media (max-width: 1200px){
        overflow-y: hidden;
    }
}

.main-menu-mobile {
    user-select: none;
    position: fixed;
    top: 60px;
    right: 0;
    z-index: z(modal);
    color: #fff;
    transform: translateX(100%);
    background-color: #000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: 0.3s;
    width: 100%;
    padding: 20px;
    @media (min-width: 1200px){
        transform: translateX(0) translateY(-150%);
        position: absolute;
        top: 75px;
        right: 0;
        text-align: right;
        width: auto;
        width: 300px;
    }
    .main-menu-mobile__list {
        @include list-style-remove;
        line-height: 1;
    }

    .main-menu-mobile__item,
    .menu-item {
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 0.1em;

        > a,
        .main-menu-mobile__link {
            text-decoration: none;
            font-size: 2rem;
            display: block;
            padding: 1.5rem 0;
        }
    }

    &.is-active {
        right: 0px;
        transform: translate3d(0, 0, 0);
        @media (min-width: 1200px){
            transform: translateX(0) translateY(0%);
        }
    }
}
