//posicionamento do menu fixo no topo
.main-header-bundle {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: z(menu);
}

.main-header {
    color: get-color(dark);
    transition: 400ms;
    height: 65px;
    h1 {
        margin: 0;
    }

    &.is-active{
        background-color: #000;
        box-shadow: 1px 5px 10px 1px rgba(black, 0.7);
        .mobile-logo{
            opacity: 1;
        }
    }
}

//menu desktop
.desktop-menu {
    display: none;
    @media (min-width: 1200px) {
        display: block;
    }
    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .main-header__logo-container {
        width: 100px;
        height: 50px;
        @media (min-width: 1200px) {
            width: 150px;
            padding-top: 20px;
            height: unset;
        }
       
    }

    .main-header__logo {
        width: 100%;
        height: 100%;
        display: inline-block;
        object-fit: contain;
    }

    .main-header__content {
        transition: 0.2s;

        display: flex;
        flex-wrap: wrap;
        flex-direction: row-reverse;
        justify-content: flex-start;

        @include media(get-bp(desktop), max) {
            display: none;
        }
    }
}

//menu mobile
.mobile-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding-top: 5px;
    .mobile-logo {
        padding-top: 5px;
        width: 100px;
        margin: 0;
        transition: 400ms;
        opacity: 0;
        position: relative;
        z-index: 80;
        img{
            filter: brightness(0) invert(1);
        }
        svg{
            width: 80px;
            height: 60px;
        }
    }

    //controle da cor do burger menu (acesso facil)
    .burger-icon {
        margin-top: 12px;
        background-color: #fff;
        &::before {
            background-color: #fff;
        }

        &::after {
            background-color: #fff;
        }
    }
}

//espacamento do conteudo pra barra do topo
.main-header-clearance {
    padding-top: var(--main-header-height);
}

.stack {
    display: grid;
    grid-template-columns: 1fr;
  }
  
  .stack span {
    font-weight: bold;
    grid-row-start: 1;
    grid-column-start: 1;
    font-size: 4rem;
    --stack-height: calc(100% / var(--stacks) - 1px);
    --inverse-index: calc(calc(var(--stacks) - 1) - var(--index));
    --clip-top: calc(var(--stack-height) * var(--index));
    --clip-bottom: calc(var(--stack-height) * var(--inverse-index));
    clip-path: inset(var(--clip-top) 0 var(--clip-bottom) 0);
    animation: stack 640ms cubic-bezier(.46,.29,0,1.24) 1 backwards calc(var(--index) * 120ms), glitch 2s ease infinite 2s alternate-reverse;
    &.logo{
        animation: stack 240ms cubic-bezier(.46,.29,0,1.24) 1 backwards calc(var(--index) * 30ms), glitch 1.2s ease infinite 4s alternate-reverse;
    }
  }
  
  .stack span:nth-child(odd) { --glitch-translate: 8px; }
  .stack span:nth-child(even) { --glitch-translate: -8px; }
  
  @keyframes stack {
    0% {
      opacity: 0;
      transform: translateX(-50%);
      text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
    };
    60% {
      opacity: 0.5;
      transform: translateX(50%);
    }
    80% {
      transform: none;
      opacity: 1;
      text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
    }
    100% {
      text-shadow: none;
    }
  }
  
  @keyframes glitch {
    0% {
      text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
      transform: translate(var(--glitch-translate));
    }
    2% {
      text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
    }
    4%, 100% {  text-shadow: none; transform: none; }
  }
