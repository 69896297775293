.media-section{
    padding-top: 50px;
    padding-bottom: 50px;
}

.media-section__title{
    text-transform: uppercase;
    text-align: center;
    font-size: 34px;
    margin-bottom: 40px;
    @media (min-width: 1200px){
        font-size: 48px;
    }
}

.media-section__listing{
    @media (min-width: 1200px){
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .media-item{
        margin-bottom: 50px;
        text-align: center;
        @media (min-width: 1200px){
            width: 28%;
            text-align: left;
        }
    }

    .media-item__icon{
        width: 60px;
        height: 60px;
        margin: auto;
        margin-bottom: 10px;
        @media (min-width: 1200px){
            margin: unset;
            margin-bottom: 10px;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .media-item__title{
        font-weight: bold;
        margin-bottom: 10px;
    }
}

.media-section__action{
    text-align: center;
    .rockstar-button{
        background-color: #fff;
        color: #000;
        border: 1px solid black;
        padding: 10px 20px;
        text-transform: uppercase;
        transition: 400ms;
        display: inline-flex;
        &:hover{
            background-color: #000;
            color: #fff;
            transform: scale(1.06);
        }
    }
}