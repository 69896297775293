.contact-section{
    padding-top: 100px;
    padding-bottom: 100px;
    background-size: cover;
    .container{
        @media (min-width: 1200px){
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}


.contact-section__text{
    color: #fff;
    @media (min-width: 1200px){
       width: 55%;
    }
    img{
        width: 15px;
        margin-left: 5px;
    }
}

.contact-section__form{
    margin-top: 30px;
    @media (min-width: 1200px){
        width: 40%;
        margin-top: 0;
    }

    input, textarea{
        width: 100%;
        background-color: rgba(black, 0.8);
        border: 1px solid #fff;
        margin-bottom: 10px;
        color: #fff;
        padding: 10px;
        &::placeholder{
            color: #fff;
        }
    }

    input[type='submit']{
        background-color: #fff;
        color: #000;
        font-weight: bold;
        width: 60%;
        margin: auto;
        margin-left: 20%;
        transition: 400ms;
        &:hover{
            background-color: #000;
            color: #fff;
            transform: scale(1.04);
        }
    }

    textarea{
        resize: none;
        height: 150px;
    }
}

.contact-section__title{
    font-weight: bold;
    text-transform: uppercase;
    font-size: 34px;
    line-height: 1.2;
    margin-bottom: 30px;
    @media (min-width: 1200px){
        font-size: 48px;
        width: 70%;
    }
}

.contact-section__subtitle{
    text-transform: uppercase;
    font-weight: 300;
    font-size: 24px;
    @media (min-width: 1200px){
        width: 70%;
        font-size: 22px;
    }
}