.clients-section{
    padding-top: 70px;
    padding-bottom: 70px;
    text-align: center;
    line-height: 1.2;
    position: relative;
    .swiper-wrapper{
        transition-timing-function : linear;
    }
}

.clients-section__button{
    width: 80px;
    height: 80px;
    position: absolute;
    top: -40px;
    left: calc(50% - 40px);
    img{
        width: 100%;
        height: 100%;
    }
}

.clients-section__title{
    text-transform: uppercase;
    text-align: center;
    font-size: 34px;
    font-weight: bold;
    margin-bottom: 10px;
    @media (min-width: 1200px){
        font-size: 48px;
    }
}

.clients-section__subtitle{
    font-size: 22px;
    margin-bottom: 40px;
    @media (min-width: 1200px){
        font-size: 28px;
        width: 70%;
        margin: auto;
        margin-bottom: 40px;
    }
}

.clients-section__line-detail{
    width: 60%;
    height: 3px;
    background-color: #000;
    margin: auto;
    margin-top: 20px;
}