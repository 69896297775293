.footer-container{
    background-color: #000;
    padding-top: 50px;
    padding-bottom: 50px;
    background-size: cover;
    background-position: center;
    @media (min-width: 1200px){
        padding-top: 100px;
        padding-bottom: 100px;
    }
    .container{
        @media (min-width: 1200px){
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .social-listing{
        display: flex;
        margin-bottom: 20px;
        img{
            width: 20px;
            margin-left: 5px;
        }
    }

    .social-item{
        background-color: #fff;
        color: #000;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        transition: 400ms;
        &:hover{
            transform: rotate(15deg);
        }
    }

    .footer-container__text{
        color: #fff;
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

.footer-container__social{
    @media (min-width: 1200px){
        width: 40%;
    }
}

.footer-container__address{
    color: #fff;
    font-size: 14px;
    margin-top: 20px;
    @media (min-width: 1200px){
        margin-top: -10px;
        width: 50%;
    }
    img{
        width: 100%;
    }
}