.amplifiers-section{
    background-color: #000;
    color: #fff;
    padding-top: 50px;
    padding-bottom: 50px;
    .container{
        @media (min-width: 1200px){
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}

.amplifiers-section__text{
    line-height: 1.2;
    margin-bottom: 50px;
    @media (min-width: 1200px){
        width: 50%;
        margin-bottom: 0;
    }
    .x-symbol{
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 10px;
    }

    .amplifiers-section__small-title{
        font-weight: 100;
        letter-spacing: 3px;
        font-size: 32px;
        @media (min-width: 1200px){
            font-size: 38px;
        }
        
    }

    .amplifiers-section__big-title{
        margin-bottom: 30px;
        span{
            font-size: 34px;
            @media (min-width: 1200px){
                font-size: 48px;
            }
        }
    }
}

.amplifiers-section__image{
    @media (min-width: 1200px){
        width: 40%;
    }
}



.stack-text {
    display: grid;
    grid-template-columns: 1fr;
}
  
.stack-text span {
    font-weight: bold;
    grid-row-start: 1;
    grid-column-start: 1;
    font-size: 4rem;
    --stack-height: calc(100% / var(--stacks) - 1px);
    --inverse-index: calc(calc(var(--stacks) - 1) - var(--index));
    --clip-top: calc(var(--stack-height) * var(--index));
    --clip-bottom: calc(var(--stack-height) * var(--inverse-index));
    clip-path: inset(var(--clip-top) 0 var(--clip-bottom) 0);
    animation: stack 640ms cubic-bezier(.46,.29,0,1.24) 1 backwards calc(var(--index) * 120ms), glitch 4s ease infinite 2s alternate-reverse;
}
 
.stack-text span:nth-child(odd) { --glitch-translate: 8px; }
.stack-text span:nth-child(even) { --glitch-translate: -8px; }
  
  