.cases-section{
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
    color: #fff;
}

.cases-section__title{
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    @media (min-width: 1200px){
        font-size: 48px;
        margin-bottom: 100px;
    }

    .stack-text{
        margin-left: 10px;
        span{
            font-size: 40px;
            @media (min-width: 1200px){
                font-size: 48px;
            }
        }
    }
}


.rock-star-item{
    .rock-star-item__row{
        @media (min-width: 1200px){
            display: flex;
            width: 100%;
            align-items: flex-start;
        }
    }
    .rock-star-item__left-box{
        position: relative;
        @media (min-width: 1200px){
            width: 40%;
            margin-right: -10%;
        }
    }
    .rock-star-item__title{
        text-transform: uppercase;
        font-weight: bold;
        font-size: 38px;
    }

    .rock-star-item__subtitle{
        text-transform: uppercase;
        font-weight: 300;
        padding-left: 30px;
        font-size: 22px;
    }

    .rock-star-item__text{
        position: relative;
        margin-top: 10px;
        padding-top: 20px;
        background-color: rgba(black, 0.4);
        padding-bottom: 40px;
        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 80%;
            height: 2px;
            background-color: #fff;
        }
    }

    .rock-star-item__center-image{
        margin-top: 30px;
        margin-bottom: 30px;
        @media (min-width: 1200px){
            width: 35%;
            margin-left: 2.5%;
        }
        img{
            width: 100%;
        }
    }

    .rock-star-item__right{
        flex: 1;
        margin-left: 30px;
        @media (min-width: 1200px){
            padding-top: 25px;            
        }
    }

    .rock-star-item__down-text{
        text-align: center;
        text-transform: uppercase;
        margin-top: 30px;
        margin-bottom: 100px;
        position: relative;
        &::before{
            content: '';
            position: absolute;
            top: 110%;
            left: calc(50% - 75px);
            width: 150px;
            height: 2px;
            background-color: #fff;
        }
        @media (min-width: 1200px){
            width: 60%;
            margin: auto;
            margin-bottom: 80px;  
            font-size: 22px;          
        }
    }

    .number-item{
        text-align: center;
        margin-bottom: 50px;
    }

    .number-item__title{
        margin-bottom: 10px;
        margin-top: 10px;
        font-size: 20px;
    }

    .number-item__text{
        font-weight: bold;
        text-transform: uppercase;
        span{
            font-size: 36px;
            line-height: 1.2;
        }
    }

    .rock-star-item__numbers-row{
        @media (min-width: 1200px){
           display: flex;
           justify-content: center;         
        }

        .number-item{
            @media (min-width: 1200px){
                width: 40%;
                margin-left: 5%;
                margin-right: 5%;        
             } 
        }
    }
}